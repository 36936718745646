@media (max-width: 768px) {
  /* #acougue {
    background: linear-gradient(135deg, #ff6b6b, #d53737);
  } */

  .receita-content img {
    border-radius: 10px;
  }
}

/* #acougue {
  background: linear-gradient(135deg, #ff6b6b, #d53737);
} */

.content-produto-dedicado {
  position: relative;
  width: 95%;
  margin: 20px auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}

.produto-dedicado-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.valor-item-graande {
  white-space: nowrap;
}

.produto-dedicado-texto {
  white-space: nowrap;
  font-size: 1rem;
  color: #fff;
  background: #020202;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 2px;
}

/* Conteúdo Dedicado: Fundo Preto */
.content-produto-dedicado {
  background-color: #020202; /* Fundo preto */
  border-radius: 8px;
  padding: 15px;
  margin: 20px 0;
}

/* Linha Separatória: Branca */
.linha-separatoria {
  width: 100%;
  height: 4px; /* Altura da linha */
  background: linear-gradient(
    to right,
    transparent,
    #ffffff,
    transparent
  ); /* Cor branca com extremidades transparentes */
  margin: 15px 0; /* Espaçamento superior e inferior */
  border-radius: 10px; /* Deixa as extremidades arredondadas */
}

.seta-receita {
  cursor: pointer;
  color: #fff;
  font-size: 1.5rem;
}

.modal-receita {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  max-height: 80vh; /* Define a altura máxima do modal como 80% da altura da janela */
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Aumentar para ficar acima do modal backdrop */
  border-radius: 15px;
  overflow-y: auto; /* Permite a rolagem vertical caso o conteúdo exceda a altura */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Z-index para garantir que o fundo esteja atrás da modal */
}

.buttons-modal-receita {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.btn-copiar-receita {
  background: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px; /* Espaçamento entre os botões */
}

.btn-fechar-receita {
  background: #d53737;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.backgroundImage {
  background: #020202;
}

li {
  font-size: 0.8rem;
}

.btn-copiar-receita:hover {
  background: #45a049;
}
