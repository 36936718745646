/* Contêiner principal que envolve tudo */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logo-container {
  width: 100%;
  position: sticky; /* Mantém a logo fixa na tela */
  top: 0;
  left: 0;
  z-index: 1000; /* Garante que fique acima dos outros elementos */
}

/* Placeholder para ocupar o espaço da logo no layout normal */
.logo-placeholder {
  width: 100%;
  height: auto; /* Altura do .background-logo */
}

.sticky-header {
  background-color: #121212;
  width: 100%;
}

/* Área da logo */
.background-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.85), #121212);
  width: 100%;
}

.logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Mantém a proporção da imagem */
  image-rendering: auto; /* Usa a renderização padrão para a maioria dos navegadores */
  image-rendering: -moz-crisp-edges; /* Para navegadores Mozilla */
  image-rendering: -webkit-optimize-contrast; /* Otimiza para alta qualidade no Webkit */
  image-rendering: initial; /* Para imagens rasterizadas específicas */
  -ms-interpolation-mode: bicubic; /* Otimização para navegadores antigos */
}

.header-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(26, 115, 232);
  height: 45px;
  padding: 10px;
}

.menu-icon {
  font-size: 2rem; /* Ajuste do tamanho do ícone */
  cursor: pointer;
}

.header-links a {
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  max-width: 85px;
  color: white;
  text-decoration: none;
  font-weight: 800;
  transition: color 0.3s ease;
  font-size: 78%;
  text-align: center;
}

.header-links a:hover {
  color: #ffeb3b; /* Efeito hover */
}

/* inicio Ícone de menu sanduíche */
.menu-icon {
  font-size: 1.5rem; /* Ajuste o valor conforme necessário */
  color: white;
  cursor: pointer;
  padding-left: 0.2rem; /* Utilize rem para espaçamento também */
}

@media screen and (max-width: 768px) {
  .menu-icon {
    font-size: 1.5rem; /* Ajuste para dispositivos menores */
  }
}

@media screen and (min-width: 769px) {
  .menu-icon {
    font-size: 1.5rem; /* Ajuste para dispositivos maiores */
  }
}

/* FIM Ícone de menu sanduíche */

/* Estilo para o conteúdo da página */
.page-content {
  padding: 20px;
}

.page-content section {
  padding: 50px 0;
}

#acougue,
#hortifruti,
#mercearia,
#padaria {
  scroll-margin-top: 130px;
}

/* Dropdown Menu (para quando o ícone de menu é clicado em mobile) */
.dropdown-menu {
  display: flex;
  flex-direction: column;
  background-color: rgb(26, 115, 232);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.dropdown-menu a {
  padding: 10px;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.dropdown-menu a:hover {
  background-color: rgba(26, 115, 232, 0.7);
}

@media (max-width: 768px) {
  .logo-container {
    width: 100%;
    position: fixed; /* Mantém a logo fixa na tela */
    top: 0;
    left: 0;
    z-index: 1000; /* Garante que fique acima dos outros elementos */
  }
  .sticky-header {
    height: auto;
    width: 100%;
    background-color: #121212;
  }

  .header-links {
    max-height: 27px;
    justify-content: space-between;
    display: flex;
    flex-wrap: nowrap;
  }

  .header-links a {
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 85px;
    color: white;
    text-decoration: none;
    font-weight: 800;
    transition: color 0.3s ease;
    font-size: 78%;
    text-align: center;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
  }
}

/* Responsividade */
@media (max-width: 768px) and (orientation: landscape) {
  .sticky-header {
    height: auto;
    width: 100%;
    background-color: #121212;
  }

  .header-links {
    max-height: 27px;
    justify-content: space-between;
    display: flex;
    padding: 10px;
    flex-wrap: nowrap;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
  }
}

@media (min-width: 769px) {
  .logo-placeholder {
    width: 100%;
    height: auto;
  }
  .logo-container {
    width: 100%;
    position: fixed; /* Mantém a logo fixa na tela */
    top: 0;
    left: 0;
    z-index: 1000; /* Garante que fique acima dos outros elementos */
  }
  .sticky-header {
    width: 100%;
    background-color: #121212;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
  }
}
