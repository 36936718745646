/* Estilos gerais para o footer */
.footer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.fale-conosco svg {
  font-size: 20px;
  margin-right: 5px;
  text-align: center;
  margin-top: -3px;
}

.facebook-icon {
  font-size: 20px; /* Ajuste o tamanho conforme necessário */
}

/* Contêiner para vídeo, horário e contato */
.footer-section.video-col {
  width: 100%;
  display: flex;
  flex-direction: row; /* Exibe o título e o vídeo lado a lado em telas maiores */
  align-items: center; /* Centraliza verticalmente o conteúdo */
  justify-content: center; /* Centraliza horizontalmente o conteúdo */
  text-align: left; /* Alinha o texto à esquerda */
  margin-bottom: 20px; /* Adiciona margem inferior */
  margin: auto;
}

.footer-section video {
  width: 100%; /* O vídeo ocupa a largura total do contêiner */
  height: auto; /* Mantém a proporção do vídeo */
  border-radius: 10px; /* Bordas arredondadas para o vídeo */
}

/* Seções do rodapé */
.footer-section {
  width: 100%;
  display: flex;
  flex-direction: column; /* Mantém a coluna em dispositivos maiores */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  margin: 10px 0; /* Adiciona margem entre seções */
}

/* Ajuste para títulos */
.video-col h3 {
  white-space: nowrap;
  font-size: 1.5rem; /* Tamanho do título maior para desktop */
  margin-bottom: 15px;
  text-align: center; /* Centraliza o texto do título */
}

/* Estilização do vídeo */
.video-col video {
  margin-top: -8px;
  width: 100%; /* Largura total do contêiner */
  max-width: 500px; /* Largura máxima do vídeo */
  height: 17rem; /* Mantém a proporção */
  border-radius: 10px; /* Bordas arredondadas para o vídeo */
}

/* Seção Horário de Atendimento e Fale Conosco */
.horario-fale-container {
  flex-direction: row;
  border: none;
  width: 100%; /* Garante que as colunas ocupem toda a largura */
  display: flex; /* Exibe as colunas lado a lado */
  justify-content: space-between; /* Espaça as colunas */
  flex-wrap: nowrap; /* Permite que as colunas se empilhem se houver pouco espaço */
}

/* Colunas para horário e contato */
.horario-col {
  display: flex;
  text-align: center;
}

.horario-col {
  max-width: 100%;
  flex-direction: column;
  display: flex;
  flex: none;
  margin: auto;
  min-width: 250px;
}

/* Estilo dos títulos de seção */
.horario-col h3,
.fale-conosco h3 {
  font-size: 1.3rem; /* Tamanho do título ajustado */
  margin-bottom: 5px; /* Margem abaixo do título */
}

/* Estilo dos horários */
.horario-col ul {
  list-style-type: none; /* Remove as bolinhas */
  padding-left: 0; /* Remove o padding padrão da lista */
}

/* Estilo dos horários */
.horario-col li {
  display: flex;
  flex-direction: row;
  gap: 7px;
}

/* Logotipo e Mapa Google */
.image-frontal {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.image-frontal .endereco-bts {
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #121212;
}

/* Estilo do mapa */
.footer-section iframe {
  margin-top: 36px;
  width: 100%;
  height: 17rem;
  border-radius: 10px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Destaque do WhatsApp */
.destaque-whatsapp {
  max-width: auto;
  width: 100%;
  border: none;
  text-align: center;
}

.destaque-whatsapp p {
  white-space: break-spaces;
  justify-content: center;
  display: flex;
  align-items: center;
  border: none;
  text-align: center;
  width: 100%;
}

.destaque-whatsapp a {
  display: inline-block; /* Exibição em bloco para botão */
  background-color: #25d366; /* Cor de fundo do botão */
  color: white; /* Cor do texto do botão */
  padding: 10px 20px; /* Espaçamento interno do botão */
  border-radius: 5px; /* Bordas arredondadas do botão */
  text-decoration: none; /* Remove sublinhado do botão */
}

.destaque-whatsapp a:hover {
  background-color: #1ebe57; /* Cor do botão ao passar o mouse */
}

/* Copyright */
.footer-section p {
  font-weight: 900;
  font-size: 12px !important;
  white-space: nowrap;
  text-align: center;
  color: #666;
  padding-bottom: 10px;
}

/* Estilos para os horários */
.horario {
  font-size: 18px;
  font-weight: 800;
  color: rgba(0, 128, 0, 0.808); /* Cor para o texto de horários */
}

/* CSS adicional para o Footer */

.map-and-rights {
  display: flex; /* Usar flexbox para alinhamento */
  justify-content: space-between; /* Espaçar os elementos igualmente */
  align-items: center; /* Alinhar verticalmente ao centro */
  width: 100%; /* Ocupa toda a largura disponível */
  margin-top: 15px; /* Margem acima do contêiner */
}

.map-and-rights iframe {
  flex: 1; /* Permite que o iframe ocupe espaço */
  min-width: 300px; /* Largura mínima para o iframe */
  height: 300px; /* Ajuste de altura para mobile */
  border-radius: 10px; /* Bordas arredondadas */
}

.rights {
  flex: 1; /* Permite que os direitos reservados ocupem espaço */
  text-align: center; /* Centraliza o texto */
}

.informativo {
  font-size: 13px;
  background-color: #f0f0f0;
  font-weight: bold;
  max-width: 50%;
  border: 1px solid #ccc;
}

.informativo p {
  white-space: break-spaces;
  margin: auto;
  max-width: 400px;
  font-size: 15px;
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
}

#section-tree {
  transform: translate(9%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
}

.footer-section {
  flex: 1; /* Todos os itens ocupam a mesma proporção de largura */
  text-align: center; /* Centraliza o texto */
  padding: 10px; /* Espaçamento interno */
  box-sizing: border-box; /* Garante que o padding não afete o tamanho total */
  min-width: 250px; /* Define uma largura mínima para que não encolham demais */
}

.footer-section p,
.footer-section a {
  margin: 0; /* Remove margens padrões */
}

.footer-section.informativo,
.footer-section.rights,
.footer-section.my-work {
  height: auto;
  border: none;
  margin-bottom: -20px;
}

.my-work a {
  color: #121212;
  font-size: 1.2rem;
  text-decoration: none;
}

.my-work a:hover {
  scale: 1.1;
  text-decoration: underline;
}

/* Para telas pequenas (dispositivos móveis) */
@media (max-width: 767px) {
  .footer {
    background: linear-gradient(135deg, #000000, #808080);

    padding: 10px; /* Reduz o padding em dispositivos móveis */
    gap: 15px; /* Ajusta o espaçamento entre as seções */
  }

  /* Destaque do WhatsApp */
  .destaque-whatsapp {
    max-width: 400px;
    width: 100%;
    border: none;
    text-align: center;
  }

  .image-frontal img {
    width: 60%; /* A imagem ocupa 60% da largura disponível */
    height: auto; /* Mantém a proporção da imagem */
    margin: auto;
    margin-bottom: -10px;
    border-radius: 10px;
    cursor: pointer; /* Muda o cursor para indicar que a imagem é clicável */
  }

  .video-wrapper {
    font-size: 10px;
    width: 100%;
    max-width: 500px; /* Largura máxima do vídeo */
    margin-left: 20px; /* Espaço entre o título e o vídeo */
  }

  iframe {
    height: 200px; /* Altura reduzida do mapa para dispositivos móveis */
  }

  /* Ajusta o iframe do Google Maps */
  .footer-section iframe {
    width: 100%; /* Largura de 100% da tela */
    height: 300px; /* Altura ajustada para dispositivos */
    border-radius: 10px; /* Bordas arredondadas */
  }

  iframe {
    height: 200px; /* Altura reduzida do mapa para dispositivos móveis */
  }

  /* Ajusta o iframe do Google Maps */
  .footer-section iframe {
    width: 100%; /* Largura de 100% da tela */
    height: 300px; /* Altura ajustada para dispositivos */
    border-radius: 10px; /* Bordas arredondadas */
  }

  .fale-conosco {
    width: 100%;
    justify-content: center;
    gap: 3px;
    white-space: nowrap;
    padding-top: 30px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex: none;
  }

  .fale-conosco p {
    flex-direction: row;
    justify-content: center;
    display: flex;
    text-align: center;
    margin: 5px 0; /* Espaçamento entre os parágrafos */
    text-align: left; /* Centraliza o texto */
    margin-bottom: 10px;
  }

  .fale-conosco p a {
    margin-left: 8px; /* Espaçamento entre o ícone e o texto */
    color: #121212; /* Mantém a cor correta para os links */
  }

  .horario-fale-container {
    flex-direction: column;
    border: none;
    width: 100%; /* Garante que as colunas ocupem toda a largura */
    display: flex; /* Exibe as colunas lado a lado */
    justify-content: space-between; /* Espaça as colunas */
    flex-wrap: nowrap; /* Permite que as colunas se empilhem se houver pouco espaço */
  }

  .social-icons a {
    font-size: 20px; /* Diminui o tamanho dos ícones sociais em telas pequenas */
  }

  .informativo p {
    white-space: break-spaces;
    margin: auto;
    max-width: 400px;
    font-size: 8px;
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
  }

  /* Estilo geral para todas as seções do rodapé */
  .footer-section {
    padding: 3px;
    border-radius: 5px; /* Bordas arredondadas */
    background-color: #fff; /* Fundo branco para melhor contraste */
  }

  .footer-section ul {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding-left: 0;
  }

  /* Logotipo e ícones sociais */
  .image-frontal {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza os itens da seção do logotipo */
  }
  .video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px; /* Largura máxima para o vídeo */
    margin: 0 auto;
    padding: 20px;
  }

  .video-apresentacao {
    width: 100%;
    max-height: 360px; /* Altura máxima do vídeo */
    object-fit: cover; /* Mantém o conteúdo do vídeo dentro dos limites */
    border-radius: 8px; /* Borda arredondada opcional */
  }

  .map-and-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .local-image img {
    max-width: 100%;
    height: auto;
  }

  .video-col video {
    margin-top: 33px;
    width: 100%;
    max-width: 500px;
    height: 15rem;
    border-radius: 10px;
  }

  .social-icons {
    gap: 0px;
    margin-top: 0px; /* Espaço acima dos ícones sociais */
    margin-bottom: 10px;
  }

  .social-icons a {
    margin-right: 15px; /* Ajuste o valor conforme necessário */
    color: #ccc; /* Estilização dos ícones se necessário */
  }

  .social-icons a:last-child {
    margin-right: 0; /* Remove margem extra do último ícone */
  }

  .social-icons a:hover {
    color: #007bff; /* Cor ao passar o mouse nos ícones */
  }

  .image-frontal .endereco-bts {
    font-size: 13px;
    white-space: nowrap;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #121212;
  }

  /* Estilização da imagem do Google Maps */
  .map-image {
    margin-top: 20px; /* Espaço acima do mapa */
    width: 100%; /* Largura total do mapa */
    height: auto; /* Mantém a proporção do mapa */
    border-radius: 10px; /* Bordas arredondadas para o mapa */
    transition: transform 0.3s ease; /* Transição suave ao passar o mouse */
  }

  .map-image:hover {
    transform: scale(1.05); /* Leve aumento ao passar o mouse */
  }

  .map-link {
    display: inline-block; /* Exibição em bloco para link */
    margin-top: 10px; /* Espaço acima do link */
    color: #333; /* Cor do texto do link */
    font-size: 16px; /* Tamanho da fonte do link */
    text-decoration: none; /* Remove sublinhado */
  }

  .map-link:hover {
    color: #007bff; /* Cor ao passar o mouse sobre o link */
  }

  video {
    border-radius: 0px;
    margin-top: -55px;
  }

  .titulo-video {
    margin-bottom: 10px;
    text-align: center;
    color: white;
    letter-spacing: 1px;
  }

  .destaque-whatsapp p {
    white-space: break-spaces;
    justify-content: center;
    display: flex;
    align-items: center;
    border: none;
    text-align: center;
    width: 100%;
  }

  .destaque-whatsapp a {
    display: inline-block; /* Exibição em bloco para botão */
    background-color: #25d366; /* Cor de fundo do botão */
    color: white; /* Cor do texto do botão */
    padding: 10px 20px; /* Espaçamento interno do botão */
    border-radius: 5px; /* Bordas arredondadas do botão */
    text-decoration: none; /* Remove sublinhado do botão */
  }

  .destaque-whatsapp a:hover {
    background-color: #1ebe57; /* Cor do botão ao passar o mouse */
  }

  /* Estilos para os horários */
  .horario {
    font-size: 18px;
    font-weight: 800;
    color: rgba(0, 128, 0, 0.808); /* Cor para o texto de horários */
  }

  .destaque-whatsapp p {
    white-space: break-spaces;
    justify-content: center;
    display: flex;
    align-items: center;
    border: none;
    text-align: center;
    width: 100%;
  }

  #section-tree {
    transform: translate(0%);
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
  }

  .my-work a {
    font-size: 13px;
  }

  .footer-section.informativo,
  .footer-section.rights,
  .footer-section.my-work {
    display: flex;
    height: auto;
    border: none;
    margin-bottom: -20px;
  }

  .informativo {
    background-color: #f0f0f0;
    font-weight: bold;
    max-width: 100%;
    border: 1px solid #ccc;
    height: auto;
  }

  .subtitulo-horario-atendimento {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 900;
  }

  .subtitulo-horario-atendimento p {
    color: #121212;
  }
  .horario-col {
    padding: 10px;
    background-color: #ccc;
  }
}

/* Ajusta a largura da imagem e centraliza-a sobre o mapa */
.map-and-image {
  position: relative; /* Para permitir posicionamento absoluto da imagem */
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagem-supermercado {
  position: absolute;
  top: 102%; /* Ajusta a altura da imagem para ficar mais distante do pin */
  left: 50%;
  transform: translate(
    -50%,
    -150%
  ); /* Aumenta a distância da imagem em relação ao pin */
  width: 80px; /* Tamanho da imagem */
  height: auto;
  z-index: 10;
  border-radius: 10px;
}
