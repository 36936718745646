.video-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto; /* Centraliza o componente */
}

.video-player {
  width: 100%;
  border-radius: 8px;
  display: block;
}

.video-player.hidden {
  display: none; /* Esconde o vídeo completamente */
}

.video-poster {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  z-index: 2; /* Fica acima de tudo */
}

.video-play-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
