.merceariaTitulo {
  background: #8d6e63;
}

.merceariaContent {
  background: #736763;
}

/* Estilo da classe para o valor */
.valor-item-medio-mercearia {
  display: flex;
  align-items: baseline;
  border: 2px solid #fff; /* Cor da borda */
  background: linear-gradient(to right, #d7b29e, #8d6e63);

  border-radius: 5px; /* Bordas arredondadas */
  padding: 5px 10px; /* Espaçamento interno */
  font-size: 1.5rem;
}

/* Estilo do cifrão */
.cifrao {
  font-weight: bold;
  margin-right: 5px; /* Espaço entre o cifrão e o valor */
}
