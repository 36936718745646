#hortifruti {
  background: linear-gradient(135deg, #9fdc8b, #4caf50);
  padding: 20px 0;
  text-align: center;
}

/* Título grande e chamativo */
.hortifruti-title {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

/* Imagem do logo hortifruti */
.tlogo-hortfruti {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tlogo-hortfruti img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Garante que a imagem fique proporcional */
}

.promocoes-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px !important;
  justify-content: center;
  padding: 10px;
}
