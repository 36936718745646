#promocoes {
  /* background: linear-gradient(135deg, #ff7043, #dab74f); */
  /* background: linear-gradient(135deg, #d3d3d3, #f5f5f5); */
  /* background: linear-gradient(135deg, #ffffff, #e8e8e8); */
  background: linear-gradient(135deg, #ffcc33, #ff5733);
}

.tag-promocoes-container {
  width: 100%;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  img {
    max-width: 500px;
    height: auto;
  }
  .card-unico {
    width: 100%;
    height: auto;
    margin-top: -18px;
  }

  .card-unico img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
}

p {
  text-transform: uppercase;
}

/* Container centralizado */
.container-titulo-interativo {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  text-align: center;
  padding: 20px;
  width: 85%;
  margin: 0 auto; /* Centraliza o contêiner */
}

/* Estilos dos títulos */
.titulo-interativo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px; /* Espaço entre as linhas de título */
}

/* Estilos para a primeira linha */
.linha-um {
  white-space: nowrap;
  font-size: 1.1rem;
  padding: 5px;
  background: linear-gradient(135deg, #302727, #646262);
  color: white;
  margin-bottom: 20px; /* Espaço entre a segunda linha e o vídeo */
  /*animation: scaleUp 5s ease-in-out infinite;  Animação aplicada apenas aos títulos */
  transform-origin: center;
}

/* Estilos para a segunda linha */
.linha-dois {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 20px; /* Espaço entre a segunda linha e o vídeo */
  animation: scaleUp 2s ease-in-out infinite; /* Animação aplicada apenas aos títulos */
  transform-origin: center;
}

.video-wrapper {
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  max-width: 400px;
  margin: auto;
  height: min-content;
}

.video-controls {
  background: #000; /* Fundo preto para destacar o vídeo */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 5px;
  border-radius: 20px;
}

.play-pause-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Ajusta o tamanho do ícone */
  color: black; /* Define uma cor visível */
  background: none;
  border: none;
  cursor: pointer;
}

.play-pause-btn svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.video-apresentacao {
  width: 100%;
  height: auto;
  min-height: 350px; /* Define a altura máxima do vídeo */
  object-fit: cover; /* Garante que a imagem da capa ocupe toda a área do vídeo */
  border-radius: 10px; /* Adiciona bordas arredondadas, se desejado */
}

.bebidas-promocao {
  white-space: NOWRAP;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin: 10px;
  background: linear-gradient(135deg, #000000, #808080);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 5px;
}

/* Animação de escala em loop */
@keyframes scaleUp {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Reduzi um pouco a escala para evitar exageros */
  }
}

/* Estilo responsivo */
@media (max-width: 768px) {
  .linha-um,
  .linha-dois {
    background: linear-gradient(135deg, #000000, #808080);
    font-size: 1.1rem !important;
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    letter-spacing: 1px;
  }
  .video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 10px;
  }

  .video-apresentacao {
    width: 100%;
    height: auto;
    min-height: 300px; /* Ajuste da altura máxima do vídeo */
    object-fit: cover; /* Garante que a imagem da capa ocupe toda a área do vídeo */
    border-radius: 10px;
  }

  .video-controls {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 20px;
  }

  .play-pause-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px; /* Ajusta o tamanho do ícone */
    color: black; /* Define uma cor visível */
    background: none;
    border: none;
    cursor: pointer;
  }

  .progress-bar {
    flex: 1;
    -webkit-appearance: none;
    appearance: none;
    background: #444; /* Fundo do range */
    height: 6px;
    margin: 0 10px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  }

  .progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #007bff; /* Cor do "botão" do progresso */
    border-radius: 50%;
    cursor: pointer;
  }

  .progress-bar::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
  }
}
