@media (max-width: 767px) {
  .padaria {
    background: linear-gradient(135deg, #fbc02d, #ff8f00);
  }

  /* Itens em linha para 'Filão Saltarelli' e 'Pão de Queijo Saltarelli' */
  .padaria.promocoes-container,
  h2 {
    display: flex;
    flex-direction: row;
    gap: 16px; /* Espaçamento entre os itens */
    justify-content: center;
    align-items: center;
    color: white;
  }

  /* Centraliza a última imagem e ajusta largura máxima */
  .img-encartelados {
    display: flex;
    background: linear-gradient(135deg, #fbc02d, #fdb65c);
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Mantém texto abaixo da imagem */
    width: 100%;
    height: auto;
    text-align: center; /* Centraliza o texto */
  }

  .img-encartelados img {
    border-radius: 0px;
    max-width: 400px; /* Define a largura máxima */
    width: 100%; /* Responsivo, adapta-se ao contêiner */
    height: auto;
  }
}
