* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Desktop */
span {
  justify-content: center;
  display: flex;
  font-size: 12px;
  align-self: baseline;
  font-weight: bold;
}

.container-valor {
  display: flex;
  justify-content: space-around; /* Espaço entre os itens */
  align-items: flex-start; /* Alinha as imagens ao topo */
  flex-wrap: wrap; /* Permite que as imagens quebrem de linha em telas menores */
  gap: 20px; /* Espaçamento entre os itens */
  padding: 10px;
}

.ofertas-validas {
  width: 110%;
  justify-content: center;
  display: flex;
  background: rgb(26, 115, 232);
  text-align: center;
  padding: 18px;
  color: white;
  font-size: 0.85rem;
  font-weight: 900;
  white-space: nowrap;
  letter-spacing: 1px;
}

/* Estilo para as imagens */
.promocoes-container img {
  width: 100%; /* A imagem ocupa toda a largura do item */
  height: auto; /* Mantém a proporção das imagens */
  max-width: 315px;
}

.title-oferta {
  font-size: 1.5rem;
  color: #121212; /* Cor chamativa para o título */
  margin: 8px 0 5px; /* Espaço ao redor do título */
  font-weight: bold;
}

.promo-item br {
  display: block; /* Garante que o <br> será um bloco */
  height: 0; /* Define a altura do <br> como zero */
  margin: 0; /* Remove qualquer margem adicional */
  padding: 0; /* Remove padding adicional */
}

.title-central-card,
.title-card {
  font-size: 3.5vw; /* Ajustável ao tamanho da tela */
}

.valor-item {
  color: 121212; /* Cor do texto branco */
  padding: 5px 10px;
  border-radius: 5px; /* Bordas arredondadas */
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 5px; /* Espaçamento acima do valor */
}

.titulo-setor {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 10px 0;
  align-items: baseline;
  height: 100%;
}

/*Cards interativos*/
.container-cards {
  flex-direction: row;
  gap: 5px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: auto; /* Para centralizar verticalmente em toda a página */
  padding: 10px;
}

.cards-interativos {
  flex-direction: column;
  border-radius: 10px;
  background: linear-gradient(
    135deg,
    rgb(180, 202, 247),
    rgb(26, 115, 232),
    rgb(180, 202, 247)
  );
  width: 29%; /* Garantir que o cartão ocupe 80% do espaço pai */
  max-width: 500px; /* Limite de largura para manter um tamanho razoável */
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white; /* Cor do texto para melhorar a legibilidade */
  padding: 5px;
}

.title-central-card {
  white-space: normal;
  text-transform: uppercase;
  margin-bottom: -6px;
  color: white;
  font-weight: 700;
  align-self: center;
  padding-top: 10px;
}

.title-card {
  white-space: normal;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: white;
  font-weight: 700;
  padding-top: 10px;
}

.eu-quero {
  font-size: 0.7rem;
  padding: 5px;
  display: flex;
  height: 100%;
  max-height: 25px;
  background-color: rgb(42 26 232);
  white-space: nowrap;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: white;
  font-weight: 700;
  text-align: center;
}

.desconto-porcentagem {
  font-family: system-ui;
  font-size: 30px;
  font-weight: 900;
}

.off {
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
}

.img-card {
  max-width: 100%; /* A imagem ocupará no máximo 100% da largura do card */
  height: auto; /* Mantém a proporção da imagem */
  border-radius: 8px; /* Bordas arredondadas, se necessário */
  margin-top: 10px; /* Espaçamento acima da imagem */
  align-self: center; /* Centraliza a imagem dentro do card */
}

.cifrao {
  font-size: 0.9em;
  margin-right: 4px;
}

.desconto-porcentagem {
  font-family: system-ui;
  font-size: 30px;
  font-weight: 900;
}

.promo-item img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 15px;
  margin-bottom: 2px;
  transition: transform 0.3s ease;
}

.acesso-rotas {
  text-transform: uppercase;
  font-weight: bold;
  color: rgb(255, 17, 0);
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-decoration: none;
}

.imagem-rotas {
  width: 100%; /* Largura total para responsividade */
  max-width: 400px; /* Limite máximo de largura */
  height: auto; /* Altura automática para manter a proporção */
  display: block; /* Garante que a imagem seja tratada como bloco */
  margin: 0 auto; /* Centraliza a imagem */
  cursor: pointer; /* Muda o cursor para indicar que a imagem é clicável */
}

.promo-item {
  border-radius: 0px;
  overflow: hidden;
  max-width: 180px;
  text-align: center;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  width: calc(50% - 15px);
  padding: 1px; /* Controla o espaçamento interno */
  margin: 1px;
}

.valor-item-grande {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #ffd700, #ffa500);
  padding: 6px;
  border-radius: 5px;
}

p {
  margin-bottom: 2%;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
  align-self: center;
  max-width: 90%;
  white-space: normal;
  font-size: 0.8rem !important;
  font-weight: bold;
}

.container-ofertas {
  height: auto;
}

.whatsapp-fixo {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 50%;
  padding: 10px;
  transition: transform 0.3s;
}

.whatsapp-fixo img {
  width: 24px;
  height: 24px;
}

/* Animação de balanço aprimorada para o ícone */
@keyframes shake {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  20% {
    transform: translateY(-5px) rotate(-15deg);
  }
  40% {
    transform: translateY(5px) rotate(10deg);
  }
  60% {
    transform: translateY(-3px) rotate(-10deg);
  }
  80% {
    transform: translateY(3px) rotate(5deg);
  }
}

/* Aplicação da animação de balanço */
.shake-icon {
  animation: shake 1s ease-in-out;
}

/* Tooltip styling */
.tooltip {
  font-size: 0.9rem;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -40px;
  right: 66%;
  transform: translateX(20%);
  background-color: #25d366;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  transition: opacity 0.3s ease;
  z-index: 1001;
}

/* Exibindo o tooltip */
.show-tooltip .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Mobile RETRATO */
@media (max-width: 767px) and (orientation: portrait) {
  .tag-promocoes-container img {
    margin: auto;
    width: 100%;
    height: auto;
    padding-bottom: 8px;
    border-radius: 1rem;
    display: flex;
    margin: auto;
    width: 100%; /* A largura das imagens será controlada pelo container */
    height: auto; /* Mantém a proporção das imagens */
  }

  .tag-promocoes-container img,
  .promo-item img,
  .img-card {
    width: 100%;
    height: auto;
    margin: auto;
    border-radius: 0.5rem;
  }
  .container-cards {
    flex-direction: row;
    gap: 5px;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: auto; /* Para centralizar verticalmente em toda a página */
    padding: 10px;
  }

  .cards-interativos {
    flex-direction: column;
    border-radius: 10px;
    background: linear-gradient(
      135deg,
      rgb(180, 202, 247),
      rgb(26, 115, 232),
      rgb(180, 202, 247)
    );
    width: 29%; /* Garantir que o cartão ocupe 80% do espaço pai */
    max-width: 500px; /* Limite de largura para manter um tamanho razoável */
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white; /* Cor do texto para melhorar a legibilidade */
    padding: 5px;
  }

  .title-central-card {
    white-space: normal;
    text-transform: uppercase;
    margin-bottom: -6px;
    color: white;
    font-weight: 700;
    align-self: center;
    padding-top: 10px;
  }

  .title-card {
    white-space: normal;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: white;
    font-weight: 700;
    padding-top: 10px;
  }

  .cifrao {
    font-size: 0.65em;
    margin-right: 4px;
    font-weight: bold;
  }

  .desconto-porcentagem {
    font-family: system-ui;
    font-size: 30px;
    font-weight: 900;
  }

  .off {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .padaria {
    background: linear-gradient(135deg, #fbc02d, #ff8f00);
  }

  .padaria.promocoes-container,
  h2 {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  .marginBottom {
    margin-bottom: 16px;
  }

  .img-encartelados img {
    border-radius: 15px;
    max-width: 400px;
    width: 100%;
    height: auto;
  }

  .img-encartelados {
    display: flex;
    background: linear-gradient(135deg, #fbc02d, #fdb65c);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    text-align: center;
  }

  .titulo-setor {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 10px 0;
    align-items: baseline;
    height: 100%;
  }

  .img-card {
    max-width: 100%; /* A imagem ocupará no máximo 100% da largura do card */
    height: auto; /* Mantém a proporção da imagem */
    border-radius: 8px; /* Bordas arredondadas, se necessário */
    margin-top: 10px; /* Espaçamento acima da imagem */
    align-self: center; /* Centraliza a imagem dentro do card */
  }

  .title-oferta {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 0px;
    max-height: 110px;
    margin-bottom: 1rem;
  }

  .title-oferta p {
    font-size: 1rem;
    color: #121212;
    margin: 8px 0 5px;
    font-weight: bold;
    white-space: break-spaces;
  }

  .title-oferta-medio p {
    font-size: 0.8rem;
    color: #121212;
    margin: 8px 0 5px;
    font-weight: bold;
    white-space: break-spaces;
  }

  .title-oferta h4:hover {
    text-decoration: underline;
  }

  /* Estilos gerais */
  .promocoes-container img {
    max-width: 180px;
    height: auto;
  }

  .promo-item-maior img {
    cursor: pointer;
    border-radius: 0.5rem;
    text-align: center;
    max-width: 180px;
    height: auto;
  }

  .valor-item {
    align-items: baseline;
    justify-content: center;
    display: flex;
    color: #121212;
    font-size: 15px;
    font-weight: bold;
    margin-top: auto;
  }

  .valor-item-medio {
    justify-content: center;
    display: flex;
    color: #121212;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: auto;
    align-items: flex-start;
  }

  .valor-item-grande {
    margin-top: -3px;
    font-size: 2em;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #000000, #808080);
    padding: 6px;
    border-radius: 5px;
    margin-bottom: 16px;
  }

  .valor-item-medio-bebida {
    padding: 5px;
    background: linear-gradient(135deg, #2e7d32, #66bb6a);
    justify-content: center;
    display: flex; /* Alterado para flex */
    color: #121212;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: auto;
    align-items: flex-start;
    text-align: center; /* Adicionado para centralização */
  }

  .valor-item-medio-promocao {
    padding: 5px;
    background: linear-gradient(to bottom, #d4b343, #ffde59);
    justify-content: center;
    display: flex; /* Alterado para flex */
    color: #121212;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 5px;
    align-items: flex-start;
    text-align: center; /* Adicionado para centralização */
    white-space: normal;
  }

  .valor-item-medio-acougue {
    padding: 5px;
    background: linear-gradient(135deg, #ff6b6b, #b71c1c);
    justify-content: center;
    display: flex; /* Alterado para flex */
    color: #121212;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 5px;
    align-items: flex-start;
    text-align: center; /* Adicionado para centralização */
    white-space: normal;
  }

  .valor-item-promocao {
    padding: 5px;
    background: linear-gradient(135deg, #2e7d32, #66bb6a);
    justify-content: center;
    display: flex; /* Alterado para flex */
    color: #121212;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: auto;
    align-items: flex-start;
    text-align: center; /* Adicionado para centralização */
  }

  .cifrao {
    padding: 0px 5px;
  }

  /* Container principal em layout flexível */
  .promocoes-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px !important;
    justify-content: center;
    padding: 10px;
  }

  /* Estilos para cada item de promoção */
  .promo-item {
    border-radius: 0px;
    overflow: hidden;
    max-width: 180px;
    text-align: center;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    width: calc(50% - 15px);
    max-height: 280px !important;
  }

  /* Imagem do produto com altura fixa para uniformizar o layout */
  .promo-item img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 15px;
    margin-bottom: 2px;
    transition: transform 0.3s ease;
  }

  /* Título reduzido para ocupar menos espaço */
  .title-oferta-medio {
    font-size: 0.8em; /* Tamanho reduzido para ajuste */
    color: #555;
    min-height: 2.5em; /* Altura mínima para uniformizar */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
  }

  /* Informação sobre a embalagem com altura uniforme */
  .embalagem-info {
    white-space: nowrap;
    font-size: 0.7em;
    color: #ef0b0b;
    margin-bottom: 0.8rem;
    min-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Limitar a largura máxima do conteúdo para o modo portrait */
@media screen and (min-aspect-ratio: 1/1) {
  body {
    max-width: 600px; /* Ajuste conforme o design desejado */
    margin: 0 auto;
  }
}

/* Estilo de aviso para orientar o usuário em landscape */
@media screen and (orientation: landscape) {
  .orientation-warning {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 24px;
    text-align: center;
    padding-top: 40vh;
    z-index: 1000;
  }
}
